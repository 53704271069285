.appBar {
  }
  
  .menuButton {
    margin-right: 16px;
  }
  
  .title {
    flex-grow: 1;
  }
  
  .navLinks {
    display: none;
  
    @media (min-width: 768px) {
      display: flex;
      gap: 20px;
    }
  
    .link {
      color: white;
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .navContainer{
    height: 80px;
  }
  