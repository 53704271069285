.hero {
  background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9) ), url("../../assets/images/aboutheader.jpg");
  z-index: 0;
  color: white;
  padding: 40px 0;
  text-align: left;
  min-height: 500px;
  background-attachment: fixed;
  // background-position: 0px -140px;
  background-repeat: no-repeat;
  background-size: cover;
}

.heroText {
  margin-top: 16px;
  margin-bottom: 24px;
}

.heroButton {
  margin-top: 24px;
  display: block;
}

.getTouch{
  display: flex;
}

.imagePlaceholder {
  width: 100%;
  aspect-ratio: 2 / 1; // Keeps the images square
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #757575;
  font-size: 1.5rem;
}

.sectionSpacing {
  margin-top: 40px;
}

.section h2 {
  margin-bottom: 40px;
}
