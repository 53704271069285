.hero {
    background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url("../../assets/images/pilated_bg-2.jpg");
    z-index: 0;
    color: white;
    padding: 40px 0;
    text-align: left;
    min-height: 300px;
    background-position: 50% -140px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }