.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .mainContent {
    flex-grow: 1;
    padding: 20px;
  }
  
  .header, .footer {
    background-color: #C1272D;
    color: white;
    text-align: center;
    padding: 10px 0;
  }
  