@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Bai Jamjuree", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.divCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.neoButtonRed{
  padding: 10px 30px;
  border-radius: 6px;
  background: #343D3B;
  box-shadow:  23px 23px 46px #1d2221,
  -23px -23px 46px #4b5855;

}

.neoButtonRed:hover {
  box-shadow:  23px 23px 46px #282f2d,
  -23px -23px 46px #404b49;
}